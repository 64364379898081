<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div>
      <div class="gradient">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#052633"
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              @click="$router.go(-1)"
              icon
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
      </div>
      <div class="px-5 pb-10 mt-n10">
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <div class="mb-3 d-flex align-start justify-center">
              <v-avatar v-if="!loading && dataSet" size="150">
                <img
                  src="../../assets/img/IconProfile.png"
                  class="obj_fit"
                  style="background: white; padding: 1px"
                />
              </v-avatar>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-circle"
                  width="100px"
                  height="100px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8" class="px-4">
            <div class="d-flex justify-end">
              <v-btn
                :disabled="
                  !loadingSave && !loading ? (edit ? true : false) : true
                "
                @click="edit = true"
                class="text-capitalize"
                text
                depressed
                dark
                style="background: rgba(38, 38, 38, 0.3)"
              >
                <v-icon class="mr-2">mdi-pencil</v-icon>
                Edit Associate
              </v-btn>
            </div>
            <div class="mt-5">
              <div v-if="!loading && dataSet">
                <p class="medium_txt bold ma-0">Nama</p>
                <v-text-field
                  :disabled="edit ? false : true"
                  v-model="dataSet.nama"
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan isi nama di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
            <div class="mt-3">
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">NIP</p>
                    <v-text-field
                      :disabled="edit ? false : true"
                      v-model="dataSet.nip"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi nip di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">Username</p>
                    <v-text-field
                      :disabled="edit ? false : true"
                      v-model="dataSet.user.username"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi username di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mt-3">
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">No. Telepon</p>
                    <v-text-field
                      :disabled="edit ? false : true"
                      v-model="dataSet.telepon"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi no. telepon di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">Email</p>
                    <v-text-field
                      readonly
                      filled
                      v-if="dataSet.user"
                      :disabled="edit ? false : true"
                      v-model="dataSet.user.email"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi email di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      readonly
                      filled
                      v-if="!dataSet.user"
                      :disabled="edit ? false : true"
                      v-model="email"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi email di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mt-3">
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">Tanggal Lahir</p>
                    <v-menu
                      :disabled="edit ? false : true"
                      v-model="showPickerLahir"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      max-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="edit ? false : true"
                          v-model="dataSet.tgl_lahir"
                          prepend-inner-icon="mdi-calendar-range"
                          readonly
                          placeholder="Silakan isi tanggal lahir di sini"
                          hide-details
                          dense
                          v-on="on"
                          outlined
                          class="rounded-lg"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dataSet.tgl_lahir"
                        @input="closeDateMenu(dataSet.tgl_lahir, 'lahir')"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="mt-3">
              <div v-if="!loading && dataSet">
                <p class="medium_txt bold ma-0">Jenis Kelamin</p>
                <v-select
                  :disabled="edit ? false : true"
                  placeholder="Silakan pilih jenis kelamin di sini"
                  outlined
                  hide-details
                  :items="optGender"
                  item-text="text"
                  v-model="dataSet.jenis_kelamin"
                  item-value="val"
                  dense
                ></v-select>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
            <div class="mt-3">
              <div v-if="!loading && dataSet">
                <p class="medium_txt bold ma-0">Alamat</p>
                <v-textarea
                  :disabled="edit ? false : true"
                  v-model="dataSet.alamat"
                  class="rounded-lg"
                  outlined
                  placeholder="Silakan pilih alamat di sini"
                  rows="3"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-textarea>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="90px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </div>
            <div class="mt-3">
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">Departemen</p>
                    <v-text-field
                      :disabled="edit ? false : true"
                      v-model="dataSet.departemen"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi departemen di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
                <v-col cols="6" class="pl-2">
                  <div v-if="!loading && dataSet">
                    <p class="medium_txt bold ma-0">Posisi</p>
                    <v-text-field
                      :disabled="edit ? false : true"
                      v-model="dataSet.jabatan"
                      class="rounded-lg"
                      outlined
                      placeholder="Silakan isi posisi di sini"
                      color="#0D47A1"
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div v-if="loading">
                    <v-skeleton-loader
                      class="rounded-lg"
                      width="90px"
                      height="20px"
                      type="image"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="rounded-lg mt-1"
                      width="100%"
                      height="40px"
                      type="image"
                    ></v-skeleton-loader>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-divider class="my-10"> </v-divider>
            <div>
              <h2 class="text-center">Info Tambahan</h2>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">No. SIP</p>
                  <v-text-field
                    :disabled="edit ? false : true"
                    v-model="dataSet.no_sipp"
                    class="rounded-lg"
                    outlined
                    placeholder="Silakan isi no. sip di sini"
                    color="#0D47A1"
                    dense
                    hide-details
                  ></v-text-field>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Tanggal Masuk</p>
                  <v-menu
                    :disabled="edit ? false : true"
                    v-model="showPickerMasuk"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="edit ? false : true"
                        v-model="dataSet.tgl_masuk"
                        prepend-inner-icon="mdi-calendar-range"
                        readonly
                        placeholder="Silakan isi tanggal masuk di sini"
                        hide-details
                        dense
                        v-on="on"
                        outlined
                        class="rounded-lg"
                        style="width: 60%"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dataSet.tgl_masuk"
                      @input="closeDateMenu(dataSet.tgl_masuk, 'masuk')"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Status Associate</p>
                  <v-radio-group
                    :disabled="edit ? false : true"
                    v-model="dataSet.status_associate"
                    hide-details
                    class="ma-0"
                    dense
                    row
                  >
                    <v-radio label="Aktif" color="#006341" value="1"></v-radio>
                    <v-radio
                      label="Tidak Aktif"
                      color="#006341"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Tanggal Keluar</p>
                  <v-menu
                    :disabled="edit ? false : true"
                    v-model="showPickerKeluar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="edit ? false : true"
                        v-model="dataSet.tgl_keluar"
                        prepend-inner-icon="mdi-calendar-range"
                        readonly
                        placeholder="Silakan isi tanggal keluar di sini"
                        hide-details
                        dense
                        v-on="on"
                        outlined
                        class="rounded-lg"
                        style="width: 60%"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dataSet.tgl_keluar"
                      @input="closeDateMenu(dataSet.tgl_keluar, 'keluar')"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Pendidikan Terakhir</p>
                  <v-select
                    :disabled="edit ? false : true"
                    placeholder="Silakan pilih pendidikan terakhir di sini"
                    outlined
                    hide-details
                    :items="optPendidikanTerakhir"
                    item-text="text"
                    item-value="val"
                    v-model="dataSet.pendidikan_terakhir"
                    dense
                  ></v-select>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Gaji</p>
                  <v-select
                    :disabled="edit ? false : true"
                    placeholder="Silakan pilih gaji di sini"
                    outlined
                    hide-details
                    :items="optGaji"
                    item-text="text"
                    item-value="val"
                    v-model="dataSet.gaji"
                    dense
                  ></v-select>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg mt-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="mt-3">
                <div v-if="!loading && dataSet">
                  <p class="medium_txt bold ma-0">Tipe Associate</p>
                  <v-radio-group
                    :disabled="edit ? false : true"
                    v-model="dataSet.tipe_associate"
                    hide-details
                    class="ma-0"
                    dense
                    row
                  >
                    <v-radio
                      label="Kontrak"
                      color="#006341"
                      value="Kontrak"
                    ></v-radio>
                    <v-radio
                      label="Tetap"
                      color="#006341"
                      value="Permanen"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                    :disabled="edit ? false : true"
                    class="rounded-lg mt-1"
                    outlined
                    placeholder="Silakan isi berapa tahun di sini"
                    v-model="dataSet.durasi"
                    color="#0D47A1"
                    dense
                    hide-details
                  ></v-text-field>
                </div>
                <div v-if="loading">
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="90px"
                    height="20px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg my-1"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    class="rounded-lg"
                    width="100%"
                    height="40px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </div>
            <div v-if="edit" class="d-flex align-center justify-end mt-4">
              <v-btn
                :disabled="loadingSave ? true : false"
                depressed
                class="mr-4 text-capitalize"
                outlined
                color="#BD9028"
                @click="edit = false"
              >
                <div style="color: black">Cancel</div>
              </v-btn>
              <v-btn
                depressed
                class="text-capitalize bg_default"
                @click="updateData"
                dark
              >
                <h4 v-if="!loadingSave">Simpan</h4>
                <h4 v-if="loadingSave">Loading</h4>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { NavbarProfile },
  name: "updateEmployee",
  props: ["selected"],
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      dataSet: "",
      showPickerLahir: "",
      showPickerMasuk: "",
      showPickerKeluar: "",
      loading: false,
      edit: false,
      loadingSave: false,
      email: "",
      errors: {
        email: null,
        password: null,
      },
      optGender: [
        { text: "Laki-laki", val: "Laki-laki" },
        { text: "Perempuan", val: "Perempuan" },
      ],
      optGaji: [
        { text: "< Rp. 3.000.000", val: "< Rp. 3.000.000" },
        {
          text: "Rp. 3.000.000 - Rp. 6.000.000 ",
          val: "Rp. 3.000.000 - Rp. 6.000.000",
        },
        { text: "> Rp. 6.000.000", val: "> Rp. 6.000.000" },
      ],
      optPendidikanTerakhir: [
        { text: "SD", val: "SD" },
        { text: "SMP", val: "SMP" },
        { text: "SMA", val: "SMA" },
        { text: "S1", val: "S1" },
        { text: "S2", val: "S2" },
        { text: "S3", val: "S3" },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Akun Associate",
          disabled: false,
          href: `/admin/mk/associate`,
        },
        {
          text: "Edit Akun Associate",
          disabled: true,
          href: `#`,
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // get data
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/associate/akun/list?filter[id]=${this.$route.params.idUser}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data[0];
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },

    // update data associate
    updateData() {
      let isEmpty = false;
      if (this.dataSet.user) {
        if (!this.dataSet.user.email) {
          this.$toast.error(`email tidak boleh kosong!`);
          isEmpty = true;
        }
        if (!this.dataSet.user.username) {
          this.$toast.error(`username tidak boleh kosong!`);
          isEmpty = true;
        }
      } else {
        if (!this.email) {
          this.$toast.error(`email tidak boleh kosong!`);
          isEmpty = true;
        }
      }
      for (const item in this.dataSet) {
        if (!this.dataSet[item]) {
          if (item == "nama" || item == "nip" || item == "jenis_kelamin") {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }

      // cek tidak ada yg kosong
      if (!isEmpty) {
        this.loadingSave = true;
        let data = {
          body: {
            id: this.$route.params.idUser,
            username: this.dataSet.user ? this.dataSet.user["username"] : "",
            nama: this.dataSet["nama"],
            jenis_kelamin: this.dataSet["jenis_kelamin"],
            tgl_lahir: this.dataSet["tgl_lahir"],
            telepon: this.dataSet["telepon"],
            nip: this.dataSet["nip"],
            departemen: this.dataSet["departemen"],
            jabatan: this.dataSet["jabatan"],
            no_sipp: this.dataSet["no_sipp"],
            alamat: this.dataSet["alamat"],
            pendidikan_terakhir: this.dataSet["pendidikan_terakhir"],
            status_associate: this.dataSet["status_associate"],
            tipe_associate: this.dataSet["tipe_associate"],
            tgl_masuk: this.dataSet["tgl_masuk"],
            tgl_keluar: this.dataSet["tgl_keluar"],
            gaji: this.dataSet["gaji"],
            email: this.dataSet.user ? this.dataSet.user["email"] : this.email,
            durasi: this.dataSet["durasi"],
            gaji: this.dataSet["gaji"],
          },
          path: `sysadmin/associate/akun/update`,
        };
        this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            this.loadingSave = false;
            if (data.code == 200) {
              this.edit = false;
              this.$emit("success");
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            } else if (data.code == 201) {
              if (data.data) {
                var key = Object.keys(data.data.errors);
                var values = Object.values(data.data.errors);
                for (let i = 0; i < key.length; i++) {
                  for (let j = 0; j < values[i].length; j++) {
                    // tampilkan semua error di pop up
                    this.$toast.error(values[i][j]);
                  }
                }
              } else {
                // tampilkan error di pop up
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
            }
          })
          .catch((e) => {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              // tampilkan error di pop up
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                // tampilkan semua error di pop up
                this.$toast.error(values[i][j]);
              }
            }
            this.loadingSave = false;
          });
      }
    },
    closeDateMenu(item, type) {
      if (type == "lahir") {
        this.showPickerLahir = false;
        this.dataSet.tgl_lahir == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else if (type == "masuk") {
        this.showPickerMasuk = false;
        this.dataSet.tgl_masuk == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.showPickerKeluar = false;
        this.dataSet.tgl_keluar == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
  },
};
</script>
<style scoped>
.gradient {
  height: 200px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(5, 38, 51, 0.8) 0%,
    #052633 100%
  );
  border-radius: 120px 0px 0px 0px;
  display: flex;
}
</style>
